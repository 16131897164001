import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/stylesheets/application.scss'
import VueTheMask from 'vue-the-mask'
import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import "vue3-snackbar/dist/style.css";
import store from "@/store";
import * as mask from "@/helpers/filters"
import KProgress from 'k-progress-v3';
import easySpinner from 'vue-easy-spinner';
const app = createApp(App);

app.use(store)
.use(router)
.use(SnackbarService)
.component('k-progress', KProgress)
.component("vue3-snackbar", Vue3Snackbar)
.use(VueTheMask)
.use(easySpinner, {
  /*options*/
  prefix: 'easy',
})
.mount('#app')

app.config.globalProperties.$filters = {
    mask
  }
