import PrimaryButton from "../../../buttons/PrimaryButton.vue";
import FacematchService from "@/services/FacematchService";

export default {
    name: "CardFacematchResult",
    components: {
        PrimaryButton,
    },

    props:{
        typeFacematch:{
            type: Number,
            default: 1
        },
        id:{
            required: true,
        }
    },

    data() {
        return {
            face_match: {},
            percent: 0,
            title: "Facematch Melhor Frame e Identidade",
            show: {
                face_match: false,
            },
        };
    },
    
    beforeMount() {
        if(this.typeFacematch == 2){
            this.title = "Facematch Identidade e Pessoa segurando documento";
        }

        this.getData();
    },

    methods: {
        getData() {
            const setData = this.setData;
            const id = this.id;

            const interval = setInterval(async function () {
                const response = await FacematchService.getFacematch(id);

                if(id == null){
                    clearInterval(interval);

                }

                if(response.data.face_match_status == 'processed'){
                    setData(response.data, 100);
                    clearInterval(interval);


                }



            }, 2000);
        },

        analyseStatus(data, status) {
            const result = {
                processed: data,
                queued: "loading",
                pending: "loading",
                "processing ": "loading",
                error: "Algo não ocorreu como esperado",
                execption: "Algo não ocorreu como esperado",
                unknown: "Algo não ocorreu como esperado",
                not_requested: "Não requisitado",
            };

            return result[status];
        },

        setData(face_match, percent) {
            this.percent = percent;
            this.face_match = face_match;
        },
    },
};