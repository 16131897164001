<template>
    <button @click="redirect()" class="btn-primary">{{ name }}</button>
</template>

<script>
export default {
    name: 'PrimaryButton',
    props: {
        name: String,
        pathRedirect: String,
        typeLink: {
            default: "internal"
        }
    },
    methods: {
        redirect() {
            if (this.typeLink == "internal") {
                this.$router.push(this.pathRedirect)
                return;
            }
            window.open(this.pathRedirect)
        }
    }
}
</script>
