import PrimaryButton from '../../../components/buttons/PrimaryButton.vue'
import SecundaryButton from '../../../components/buttons/SecundaryButton.vue'
import ButtonReturn from '../../../components/buttons/ButtonReturn.vue'

export default {
    name: 'LivenessIndex',
    components: {
        PrimaryButton,
        SecundaryButton,
        ButtonReturn,
    }
}