<template>
    <button @click="redirect()" class="secundarybutton">
        <img :src="linkimage" alt="">
        {{ name }}
    </button>
</template>

<script>
export default {
    name: 'SecundaryButton',
    props: {
        pathRedirect: String,
        linkimage: String,
        name: String,
    },

    methods: {
        redirect(){
            window.location.href = this.pathRedirect
        }
    }
}
</script>
