import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import CardLivenessResult from '@/components/cards/results/cardLivenessResult/CardLivenessResult.vue'
import CardOcrResult from '@/components/cards/results/cardOcrResult/CardOcrResult.vue';
import CardFacematchResult from '@/components/cards/results/cardFacematchResult/CardFacematchResult.vue';
import CardBackgroundCheckResult from '@/components/cards/results/cardBackgroundCheckResult/CardBackgroundCheckResult.vue';
import ResultService from '@/services/ResultService';
import { mapActions } from 'vuex';

export default {
    name: 'OnboardingResult',
    components: {
        PrimaryButton,
        CardLivenessResult,
        CardOcrResult,
        CardFacematchResult,
        CardBackgroundCheckResult
    },

    data(){
        return {
          data: [],
        }
    },

    async beforeMount(){
       await this.getResult();
    },


    methods:{
        ...mapActions('loading', ['ActionLoadingChange']),

        async getResult(){
            await this.ActionLoadingChange();

            const response =  await ResultService.getResult(this.$route.params.id)

            if(!response.error){
                await this.ActionLoadingChange();
                this.data = response.data;
                return;
            }
            
            await this.ActionLoadingChange();
            const msg = response.data.response.data?.mensagem ? response.data.response.data.mensagem : 'Algo não saiu como esperado. Tente novamente mais tarde.'
            this.$snackbar.add({ type: 'error',  text: msg})
        }
    }
}