<div>
    <div class="center">
        <h3>Resultado</h3>
        <div class="group-items">
            <p class="result-item">
                <b>scene: </b>true 
            </p>
            <div class="row2">
                <p class="result-item">
                    <b>same_face: </b>true
                </p>
                <p class="result-item">
                    <b>multiple_faces: </b>1
                </p>
            </div>
            <p class="result-item">
                <b>% (mouth - mouth_expected) >= </b> 0
            </p>
            <p class="result-item">
                <b>% (blinks - blinks_expected) >= </b> 0
            </p>
        </div>
        <div class="message-result">
            <!-- <img src="../../assets/images/icon-validado.svg" alt="Icone Resultado"> -->
            <h2>Tudo Certo!</h2>
            <p>Validação efetuada com sucesso!</p>
        </div>
        <!-- <PrimaryButton name="Voltar" pathRedirect="/" /> -->
        <button @click="">Refazer Validação</button>
    </div>
</div>