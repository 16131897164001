<div class="center">
    <div class="head">
        <ButtonReturn pathRedirect="/"/>
        <h3>Onboarding</h3>
    </div>
    <div class="content">
        <img src="../../../../public/assets/images/ilustration-onboarding.svg" alt="Ilustração Onboarding" class="illustration">
        <p>Tenha em mãos um Documento oficial com foto, e clique no botão "Iniciar".</p>
        <h5>Importante</h5>
        <p>Para concluir a validação, é importante estar em um local bem iluminado e sem acessórios que possam prejudicar a visualização (Boné, óculos, máscara).</p>
    </div>
    
    <PrimaryButton name="Iniciar" pathRedirect="/onboarding/liveness"/>

</div>