<div>
        <div class="card" @click.prevent="show.backgroundcheck = !show.backgroundcheck">
            <div class="desc">
                <h4 v-show="show.backgroundcheck">Backgroundcheck  <span class="close"></span></h4>
                <h4 v-show="!show.backgroundcheck">Backgroundcheck  <span class="open"></span></h4>   
            
                <div class="message-result-success" v-if="percent >= 100">
                    <p>Validação efetuada com sucesso!</p>
                </div>
            </div>
        </div>

        <Transition>
            <div v-show="show.backgroundcheck">
                <div class="group-items">
                    <k-progress v-if="percent != 100" status="success" type="line" :percent="percent">
                    </k-progress>

                    <div class="row2">
                        <p class="result-item">
                            Nome:
                            <b>{{backgroundcheck.name}}</b>
                        </p>
                        <p class="result-item">
                            CPF:
                            <b>{{
                                $filters.mask.cpf(backgroundcheck.cpf)
                                }}</b>
                        </p>

                    </div>

                    <div class="row2">
                        <p class="result-item">
                            Morte:
                            <b v-if="backgroundcheck.death == null">Falso</b>
                            <b v-if="backgroundcheck.death != null">Verdadeiro</b>
                        </p>
                        <p class="result-item">
                            Situação:
                            <b>{{backgroundcheck.situation}}</b>
                        </p>
                    </div>

                    <p class="result-item">
                        Data Nascimento:
                        <b>{{$filters.mask.format_date(backgroundcheck.birth_date)}}</b>
                    </p>

                    <p class="result-item">
                        ID Requisição:
                        <b>{{id}}</b>
                    </p>


                </div>
                <!-- <PrimaryButton name="Refazer Validação" pathRedirect="/" v-if="percent >= 100" /> -->
            </div>
        </Transition>
</div>