import ButtonReturn from '../../../../components/buttons/ButtonReturn.vue'
import PrimaryButton from '../../../../components/buttons/PrimaryButton.vue'
import SecundaryButton from '../../../../components/buttons/SecundaryButton.vue'
import OcrDocumentsCNHValidation from '../../validation/OcrDocumentsValidation.vue'

export default{
    name: 'OCRDocumentsCNH',
    components: {
        ButtonReturn,
        PrimaryButton,
        SecundaryButton,
        OcrDocumentsCNHValidation,
    },
    methods: {
        openForm() {
            this.show_modal = !this.show_modal
        },
        closeForm() {
            this.show_modal = false;
        }
    },
    data() {
        return {
            show_modal: false,
        }
    },
}