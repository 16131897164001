
    <div class="center area-form">
        <div class="head">
            <ButtonReturn pathRedirect="/"/>
            <h3>Background Check</h3>
        </div>
        <div class="content">
            <img class="illustration" src="../../../../public/assets/images/ilustration-bcheck.svg" alt="Ilustração Background Check">
            <InputText label="Informe o CPF:" v-model="form.cpf" :mask="['###.###.###-##']"/>
            <button @click="sendForm()" class="btn-primary">Enviar</button>
            <!-- <PrimaryButton name="Enviar" pathRedirect="/resultado" /> -->
        </div>
    </div>