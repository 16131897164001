import api from "./Api"

export default {
    async sendResult(postData) {
        console.log(postData)
        const response = await api.post('/result/', postData)
            .then((response) => response)
            .catch(err => err);
        return response;
    },

    async getResult(id) {
        console.log(id)
        const response = await api.get(`/result/${id}`)
            .then((response) => response)
            .catch(err => err);
        return response;
    },    
}