import { createRouter, createWebHistory } from 'vue-router';

import Home from './views/home/HomeScreen.vue';
import LivenessForm from './views/liveness/index/LivenessIndex.vue';
import LivenessResult from './views/livenessresult/LivenessResultScreen.vue';
import ResultScreen from './views/result/ResultScreen.vue';
import BackgroundCheckIndex from './views/backgroundcheck/index/BackgroundCheckIndex.vue';
import LivenessValidation from './views/liveness/validation/LivenessValidation.vue';
import LivenessConfirm from './views/liveness/confirm/LivenessConfirm.vue';
import BackgroundCheckConfirm from './views/backgroundcheck/confirm/BackgroundCheckConfirmation.vue';
import OCRDocuments from './views/ocrdocuments/OcrDocuments.vue';
import OCRDocumentsRG from './views/ocrdocuments/rg/index/OcrDocumentsRG.vue';
import OCRDocumentsCNH from './views/ocrdocuments/cnh/index/OcrDocumentsCNH.vue';
import OCRDocumentsPassport from './views/ocrdocuments/passport/index/OcrDocumentsPassport.vue';
import FacialRecognition from './views/faceid/index/FacialRecognition.vue';
import FacialRecognitionValidation from './views/faceid/validation/FacialRecognitionValidation.vue';
import FacialRecognitionResult from './views/faceid/result/FacialRecognitionResult.vue';
import OnboardingIndex from './views/onboarding/index/OnboardingIndex.vue';
import OnboardingLiveness from './views/onboarding/liveness/OnboardingLiveness.vue';
import OnboardingOCR from './views/onboarding/ocrdocuments/OnboardingOCR.vue';
import OCRcamera from './views/onboarding/ocrdocuments/ocrcamera/OCRCamera.vue';
import OnboardingResult from './views/onboarding/result/OnboardingResult.vue';
import FormOcr from "./views/ocrdocuments/formOcr/FormOcr.vue"
import OcrResult from "./views/ocrdocuments/result/OcrResult.vue"

import VideoSelfie from './views/selfie/VideoSelfie.vue';


const routes = [
    {
    path: '/',
    name: 'home',
    component: Home,
},
{
    path: '/liveness',
    name: 'liveness',
    component: LivenessForm,
},
{
    path: '/resultado',
    name: 'result',
    component: ResultScreen,
},
{
    path: '/backgroundcheckconfirm',
    name: 'backgroundcheckconfirm',
    component: BackgroundCheckConfirm,
},
{
    path: '/livenessconfirm',
    name: 'livenessconfirm',
    component: LivenessConfirm,
},
{
    path: '/livenessresult',
    name: 'livenessresult',
    component: LivenessResult,
},
{
    path: '/backgroundcheck',
    name: 'backgroundcheckindex',
    component: BackgroundCheckIndex,
},
{
    path: '/livenessvalidacao',
    name: 'livenessvalidacao',
    component: LivenessValidation,
},
{
    path: '/backgroundcheckconfirm',
    name: 'backgroundcheckconfirm',
    component: BackgroundCheckConfirm,
},
{
    path: '/ocrdocumentos',
    name: 'ocrdocuments',
    component: OCRDocuments,
},
{
    path:"/ocrdocumentos/camera",
    name: 'ocrdocumentsCamera',
    component: FormOcr,
},
{
    path:"/ocrdocumentos/result",
    name: 'ocrdocumentsResult',
    component: OcrResult,
},

{
    path: '/ocrdocumentos/rg',
    name: 'ocrdocumentsRG',
    component: OCRDocumentsRG,
},
{
    path: '/ocrdocumentos/cnh',
    name: 'ocrdocumentsCNH',
    component: OCRDocumentsCNH,
},
{
    path: '/ocrdocumentos/passaporte',
    name: 'ocrdocumentsPassport',
    component: OCRDocumentsPassport,
},
{
    path: '/reconhecimentofacial',
    name: 'facialrecognition',
    component: FacialRecognition,
},
{
    path: '/reconhecimentofacialvalidacao',
    name: 'facialrecognitionvalidation',
    component: FacialRecognitionValidation,
},
{
    path: '/reconhecimentofacialresultado',
    name: 'facialrecognitionresult',
    component: FacialRecognitionResult,
},

{
    path: '/selfie',
    name: 'selfie',
    component: VideoSelfie,
},
{
    path: '/onboarding',
    name: 'onboarding',
    component: OnboardingIndex,
},
{
    path: '/onboarding/liveness',
    name: 'onboardingliveness',
    component: OnboardingLiveness,
},
{
    path: '/onboarding/ocr',
    name: 'onboardingocr',
    component: OnboardingOCR,
},
{
    path: '/onboarding/ocr/camera',
    name: 'onboardingocrcamera',
    component: OCRcamera,
},
{
    path: '/onboarding/result/:id',
    name: 'onboardingresult',
    component: OnboardingResult,
},
];

const router = createRouter({
    history: createWebHistory(),
    routes
});
export default router;