import CheckBox from '../../components/inputs/CheckBox.vue'
import CardHome from '../../components/cards/CardHome.vue'

export default {
    name: 'HomeScreen',
    components: {
        CheckBox,
        CardHome,
    },

    data(){
        return{
            terms:false,
        }
    },

    beforeMount(){
        this.terms = localStorage.getItem('terms');
    },

    watch:{
        terms(value){
            localStorage.setItem('terms', value)
        }
    }
}