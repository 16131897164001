import api from "./Api"

export default {
    async getFacematch(id) {
        const response = await api.get(`/facematch/${id}/`)
            .then((response) => response)
            .catch(err => err);
        return response;
    },

    async postFacematch(postData) {
        const response = await api.post('/facematch/', postData)
            .then((response) => response)
            .catch(err => err);
        return response;
    },
}