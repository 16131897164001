<template>
  <div class="themenu">
    <div class="center">
      <router-link to="/">
        <img src="../assets/images/logo-life4safe.svg" alt="Logo Life4Safe">
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheMenu',
  components: {
  }
}
</script>

<style lang="scss" scoped>
.themenu{
  background-color: var(--white-light);
  padding: 2rem 1rem;
  text-align: center;
  .center{
    padding: 0;
    position: relative;
  }
}
</style>

