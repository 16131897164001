import PrimaryButton from "../../../buttons/PrimaryButton.vue";
import BackgroundCheckService from "@/services/BackgroundcheckService.js";

export default {
    name: "CardBackgroundcheckResult",
    components: {
        PrimaryButton,
    },
    props:{
        id:{
            required: true,
        }
    },

    data() {
        return {
            backgroundcheck: {},
            percent: 0,
            show: {
                backgroundcheck: false,
            },
        };
    },

    beforeMount() {
        this.getData();
    },

    methods: {
        async getData() {

            const response = await BackgroundCheckService.getBackgroundcheck(this.id);

            if(!response.error){
                this.setData(response.data, 100);
            }

        },

        setData(backgroundcheck, percent) {
            this.percent = percent;
            this.backgroundcheck = backgroundcheck;
        },
    },
};