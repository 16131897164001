<div>
    <div class="screenvalidation">
        <TheMenu />
        <ButtonReturn pathRedirect="/ocrdocumentos" />
        <h4 class="top">Identidade - Frente</h4>
        <!-- <Camera /> -->
        <div class="footer-facematch">
            <h4>Certifique que as informações do documento estão nítidas para validação</h4>
            <SecundaryButton :linkimage="'/assets/images/icon-camera.svg'" />
            <!--<ConfirmButton name="Enviar" />-->
        </div>
    </div>
</div>