<template>
  <div class="area-camera">
    <video ref="video" autoplay id="video" v-if="video" :src="src"></video>

    <div class="footer-facematch">
      <div v-if="!recording" class="center-buttons" style="padding: 0.5rem">
        <button
          class="secundarybutton"
          v-if="video"
          @click="startRecording('video1')"
        >
          <img src="../../public/assets/images/icon-camera.svg" alt="" />
        </button>
      </div>
      <!-- <button @click="download('video1')">get_app</button> --> <!-- Botao para baixar o video-->
      <div v-if="recording" class="center-buttons" style="padding: 0.5rem">
        <button
          class="btn-primary"
          v-if="video"
          @click="stopRecording('video1')"
          id="capture"
        >
          Stop
        </button>
      </div>

      <!-- <div class="center-buttons">
        <button class="btn-confirm" v-if="video" id="capture">
          <img src="/assets/images/icon-check.svg" alt="" />
          Confirmar
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
let RecordRTC = require("recordrtc");

export default {
  name: "CameraComponent",
  components: {},
  data: function () {
    return {
      video: true,
      blob: "",
      recorder: [],
      camera: [],
      recording: false,
      src: "",
    };
  },
  methods: {
    successCallback(stream) {
      const options = {
        mimeType: "video/webm\;codecs=vp9", // eslint-disable-line
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 128000,
        timeSlice: 2000,
        bitsPerSecond: 128000, // if this line is provided, skip above two
      };
      this.stream = stream;
      this.recordRTC = RecordRTC(stream, options);
      this.recordRTC.startRecording();
      let video = this.$refs.video;
      video.src = window.URL.createObjectURL(stream);
    },
    errorCallback() {
      //handle error here
    },

    processVideo(audioVideoWebMURL) {
      let video = this.$refs.video;
      let recordRTC = this.recordRTC;
      video.src = audioVideoWebMURL;
      this.blob = recordRTC.getBlob();
      this.file = new File([this.blob], "record");
      video.src = URL.createObjectURL(new Blob([this.blob]));
    },

    startRecording() {
      this.poster = "";
      this.recording = true;

      let mediaConstraints = {
        video: {
          mandatory: {
            minWidth: 700,
            minHeight: 720,
          },
        },
        audio: true,
      };
      navigator.mediaDevices
        .getUserMedia(mediaConstraints)
        .then(this.successCallback.bind(this));
    this.successCallback(this.getCamera());


    },
    stopRecording() {
      this.recording = false;
      this.poster = "";
      let recordRTC = this.recordRTC;
      recordRTC.stopRecording(this.processVideo.bind(this));
      let stream = this.stream;
      stream.getAudioTracks().forEach((track) => track.stop());
      stream.getVideoTracks().forEach((track) => track.stop());
    },
    download() {
      this.recordRTC.save("video.mp4");
    },

    async getCamera() {
      return new Promise(function (resolve) {
        navigator.mediaDevices
          .getUserMedia({video:true, audio:true})
          .then((mediaStream) => {
            let video = document.querySelector("#video");
            video.srcObject = mediaStream;
            video.play();
            resolve(mediaStream);
          });
      });
    },
  },

  beforeMount(){

  },

  mounted: function () {
    let video = this.$refs.video;
    video.muted = false;
    video.controls = true;
    video.autoplay = false;
    // this.successCallback(this.getCamera());

  },
};
</script>

<style lang="scss">
.area-camera{
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  video{
    input{
      widows: 10rem;
    }
  }
}
video,
canvas {
    height: 100vh;
    height: calc(100vh - 20rem);
}

.center-buttons {
  display: flex;
  justify-content: center;
}

.footer-facematch {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--white);
  padding-bottom: 1rem;
  h4 {
    background-color: var(--white-light);
    text-align: center;
    margin: 0;
    padding: 0.5rem;
  }
}
</style>
