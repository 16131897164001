<div class="center">
    <div class="screenvalidation">
        <div class="head">
            <ButtonReturn pathRedirect="/ocrdocumentos"/>
            <h3>OCR de Documentos</h3>
        </div>
        <div class="content">

            <h4>
                {{stepTexts.title}}
            </h4>
            <p>{{stepTexts.documentText}}</p>

            <DocFront v-if="step == 1"  />
            <DocVerse v-if="step == 2"  />
            <DocSelfie v-if="step == 3"  />
            
        </div>
        <img  :src="imageSrc" style="width:300px;display: block; margin: 2rem auto 0;"/>

        <div v-if="fieldCpf" style="display:grid">
            <InputText label="Informe o CPF:" v-model="cpf" :mask="['###.###.###-##']"/>

            <button style="margin-top:10px" @click="sendCpf" class="btn-primary" >Enviar</button>
        </div>



        <div style="display:grid">
        
        <InputFileCamera v-if="step == 1" @file="readFile($event, 'front')"/>
        <InputFileCamera v-if="step == 2"  @file="readFile($event, 'back')"/>

        <button  @click="nextStep" class="btn-primary" v-if="step < 3 && nextAvailable()" >Avançar</button>

        </div>

    </div>
</div>