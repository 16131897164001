<div>
    <div class="center">
        <div class="head">
            <ButtonReturn pathRedirect="/"/>
            <h3>OCR de Documentos</h3>
        </div>
        <img src="../../../public/assets/images/ilustration-ocr.svg" alt="Ilustração OCR documentos" class="illustration">
        <p>
            <b>
                Tudo pronto!
            </b>
            <p>Agora nos envie algumas fotos do seu documento com foto.</p>
            <p>Clique em "Começar" e siga as instruções que aparecerão na tela.</p>
        </p>
        <PrimaryButton name="Começar" pathRedirect="/ocrdocumentos/camera"/>
        
    </div>

</div>