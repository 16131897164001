<div>
    <div class="center">
        <div class="head">
            <h3>Reconhecimento Facial</h3>
        </div>
        <div class="message-result" v-if="percent >= 100" >
            <img src="../../../../public/assets/images/icon-validado.svg" alt="Icone Resultado">
            <h3>Validação efetuada com sucesso!</h3>
        </div>
        
        <div class="message-result" v-if="percent < 100" >
            <easy-spinner/>
        </div>
        
        
        <div class="group-items" v-if="percent >= 100">
            <h5>Processo Concluido</h5>
            <k-progress v-if="percent != 100" status="success" type="line" :percent="percent">
            </k-progress>
            

            <div class="row2">
                <p class="result-item">
                    <b>Facematch:</b>
                    <span v-if="face_match.face_match">Verdadeiro</span>
                    <span v-if="!face_match.face_match">Falso</span>
                </p>

            </div>
            <div class="row2">

                <p class="result-item">
                    <b>Primeira Imagem:</b>
                    <img :src="face_match.image_a">
                </p>

                <p class="result-item">
                    <b>Segunda Imagem:</b>
                    <img :src="face_match.image_b">
                </p>
            </div>

            <div class="row2">
                <p class="result-item">
                    <b>Data:</b>
                    <span>{{ $filters.mask.format_date(face_match.created_at) }}</span>
                </p>
                <p class="result-item">
                    <b>Horário:</b>
                    <span>{{ $filters.mask.format_hour(face_match.created_at) }}</span>
                </p>
            </div>

            <p class="result-item">
                <b>ID Requisição:</b>
                <span>{{ face_match.RowKey }}</span>
            </p>
        </div>



        <PrimaryButton name="Refazer Validação" pathRedirect="/" v-if="percent >= 100" />
    </div>
</div>