import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import CardLivenessResult from '@/components/cards/results/cardLivenessResult/CardLivenessResult.vue'
import CardOcrResult from '@/components/cards/results/cardOcrResult/CardOcrResult.vue';
import CardFacematchResult from '@/components/cards/results/cardFacematchResult/CardFacematchResult.vue';
import CardBackgroundCheckResult from '@/components/cards/results/cardBackgroundCheckResult/CardBackgroundCheckResult.vue';

export default {
    name: 'OcrResult',
    components: {
        PrimaryButton,
        CardLivenessResult,
        CardOcrResult,
        CardFacematchResult,
        CardBackgroundCheckResult
    },

    data(){
        return {
            liveness:{},
            percent: 0,
            show:{
                liveness:false
            }
        }
    },

}