<template>
    <div class="ilustration doc-front">
        <img src="../../../public/assets/images/doc-front.png" alt="Documento Oficial com Foto Identidade">
    </div>
</template>
<script>
export default {
    name: 'DocFront',
}

</script>
<style lang="scss" scoped>
.ilustration {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    img {
        max-width: 70%;
    }
}
</style>