import * as types from './mutation-types';

export default {

    [types.SET_BACKGROUNDCHECK] (state,payload){
        state.backgroundCheck = payload;
    },
    
    [types.SET_LIVENESS] (state,payload){
        state.liveness = payload;
    },

}