<div>
    <div class="center">
        <router-link to="/ocrdocumentos">
            <ButtonReturn pathRedirect="/" />
        </router-link>
        <p>Tire duas fotos:</p>
        <ul>
            <li>
                A primeira da <b>frente</b> do seu documento.
            </li>
            <li>
                A segunda, do <b>verso</b> ddo seu documento, como mostra a imagem abaixo.
            </li>
        </ul>
        <div class="images">
            <div>
                <div class="img-item">
                    <img src="../../../../assets/images/doc-front.png" alt="Frente do documento de identidade">
                    <span class="border-top-left  border-validated"></span>
                    <span class="border-top-right  border-validated"></span>
                    <span class="border-bottom-left  border-validated"></span>
                    <span class="border-bottom-right  border-validated"></span>
                </div>
                <h4 class="text-validated">Frente</h4>

                <label class="secundarybutton">
                    <img src="../../../../assets/images/icon-camera.svg" alt="Ícone câmera">
                    <input type="file" accept="image/*;capture=camera">
                </label>
            </div>
            <div>
                <div class="img-item">
                    <img src="../../../../assets/images/doc-verse.png" alt="Frente do documento de identidade">
                    <span class="border-top-left"></span>
                    <span class="border-top-right"></span>
                    <span class="border-bottom-left"></span>
                    <span class="border-bottom-right"></span>
                </div>
                <h4 class=" ">Verso</h4>

                <label class="secundarybutton">
                    <img src="../../../../assets/images/icon-camera.svg" alt="Ícone câmera">
                    <input type="file" accept="image/*;capture=camera">
                </label>
            </div>
        </div>
        <PrimaryButton name="Enviar" pathRedirect="/resultado" />
    </div>
    <OcrDocumentsRGValidation v-if="show_modal" :closeFn="closeForm" />
</div>