<template>
  <div>
    <teleport to="body">
      <vue3-snackbar top center :duration="4000"></vue3-snackbar>
    </teleport>
    <LoadingScreenVue v-if="loading"/>

    <router-view></router-view>
  </div>
</template>

<script>
import LoadingScreenVue from "./components/loading/LoadingScreen.vue";
import store from './store';

export default {
  name: 'App',
  components: {
    LoadingScreenVue
  },

  computed:{
    loading: function (){
      return store.getters['loading/isLoading']
    },
  },
}
</script>

