import axios from "axios";

const api = axios.create({
    //process.env.VUE_APP_BASE_URL_API,
    //baseURL: 'http://127.0.0.1:8000/'
    baseURL: 'https://demo-api.live4safe.com.br/'
});

api.interceptors.response.use(
    response => {
         return {error: false, data: response.data};
    },
    error => 
    {   
        return {error: true, data: error};
        // return Promise.reject({error: true, data: error.response.data});     
    }
);

export default api;
