<div class="center">
    <div class="head">
        <h3>Resultado</h3>
    </div>
    <div class="group-cards">

        <CardLivenessResult v-if="Object.keys(data).length > 0" :id="data.liveness_id" />

        <CardOcrResult v-if="Object.keys(data).length > 0" :id="data.ocr_id" />

        <CardOcrResult v-if="Object.keys(data).length > 0" :typeOcr="2" :id="data.ocr_people_id" />

        <CardFacematchResult v-if="Object.keys(data).length > 0" :id="data.face_match_liveness_id" />

        <CardFacematchResult v-if="Object.keys(data).length > 0" :typeFacematch="2"
            :id="data.face_match_photo_people" />

        <CardBackgroundCheckResult v-if="Object.keys(data).length > 0" :id="data.cpf" />

        <PrimaryButton name="Voltar" pathRedirect="/" />
    </div>
</div>