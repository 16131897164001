import * as types from "./mutation-types";


export const ActionSetBackgroundCheck = ({commit}, payload) => {
    commit(types.SET_BACKGROUNDCHECK, payload)
}

export const ActionSetLiveness = ({commit}, payload) => {
    commit(types.SET_LIVENESS, payload)
}





