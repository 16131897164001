import SecundaryButton from '../../../../components/buttons/SecundaryButton.vue';
import ButtonReturn from '../../../../components/buttons/ButtonReturn.vue';
import CameraVideo from '../../../../components/CameraVideo.vue';
import InputFileCamera from "@/components/inputs/InputFileCamera.vue"
import BackgroundcheckService from '@/services/BackgroundcheckService';
import FacematchService from '@/services/FacematchService';
import OCRService from '@/services/OCRService.js';
import LivenessService from '@/services/LivenessService';
import InputText from '@/components/inputs/InputText.vue';
import { mapActions } from 'vuex';
import ResultService from '@/services/ResultService';
import DocFront from '../../../../components/illustration/DocFront.vue';
import DocVerse from '../../../../components/illustration/DocVerse.vue';
import DocSelfie from '../../../../components/illustration/DocSelfie.vue';

export default {
    name: 'OCRCamera',
    components: {
        SecundaryButton,
        ButtonReturn,
        CameraVideo,
        InputFileCamera,
        InputText,
        DocFront,
        DocSelfie,
        DocVerse,
    },

    data() {
        return {
            fieldCpf:false,
            cpf: '',
            result: "",
            photo: {
                front: '',
                back: '',
                people: ''
            },

            step: 1,
            stepTexts: {
                title: "Documento Oficial com Foto.",
                documentText: "Tire uma foto da FRENTE do seu Documento (RG ou CNH), como ilustrado abaixo.",
            },

            bestFrameCaptured: false,
        }
    },

    methods: {
        ...mapActions('loading', ['ActionLoadingChange']),

        async stepOne() {
            this.step = 1
            this.stepTexts.title = "Documento Oficial com Foto.";
            this.stepTexts.documentText = "Tire uma foto da frente do seu documento.";
            
        },
        async stepTwo() {
            this.step = 2
            this.stepTexts.title = "Documento Oficial com Foto.";
            this.stepTexts.documentText = "Tire uma foto da Verso do seu Documento, como ilustrado abaixo.";
            this.getLiveness();

        },
        async stepThree() {
            this.step = 3
            this.stepTexts.title = "Foto segurando o seu documento!";
            this.stepTexts.documentText = "Tire uma foto segurando o seu documento.";
            await this.sendOCR()

        },

        async stepFinal(){
            await this.ActionLoadingChange();
            this.sendOCR(false);
            await this.sendFacematch();
            await this.getOCR();
            await this.ActionLoadingChange();


        },

        getLiveness(){

            const liveness_id = localStorage.getItem('liveness_id');
            const sendFacematch = this.sendFacematch;

            const errors = ['error', 'execptionexecption', 'unknown', 'not_requested'];

            const interval = setInterval( async function() {
                const response = await LivenessService.getLiveness(liveness_id);

                const best_frame_status = response.data.best_frame_status
     
                if(errors.includes(best_frame_status)){
                    clearInterval(interval)
                }
              
                if(best_frame_status == 'processed'){
                    sendFacematch(liveness_id);
                    clearInterval(interval)

                }

              
            }, 2000);
        },

        async getOCR(){

            const response = await OCRService.getOCR(localStorage.getItem('ocr_id'));
            const ocr_status = [
                'error', 'execptionexecption', 'unknown', 'not_requested',
                'processed'
            ] 

            const ocr_status_waitng = [
                'queued','pending','processing',
            ]

            if(response.error){
                const msg = response.data.response.data?.mensagem ? response.data.response.data.mensagem : 'Algo não saiu como esperado. Tente novamente mais tarde.'
                this.$snackbar.add({ type: 'error',  text: msg})
                return false  
            }

            if(ocr_status_waitng.includes(response.data.ocr_status)){
                await this.getOCR();
                return false;
            }
            
            if(ocr_status.includes(response.data.ocr_status) && response.data.cpf == null){
                this.fieldCpf = true;
                this.$snackbar.add({ type: 'error',  text: "Parece que seu documento não contem cpf, porfavor preencher no campo a baixo"});
                this.stepTexts.title = "Informe seu CPF!";
                this.stepTexts.documentText = "Parece que seu documento não contem cpf, porfavor preencher no campo a baixo";
                return false
            }

            await this.getBackgroundCheck(response.data.cpf)
            this.redirectResultPage();


        },

        readFile(file, index){
            this.photo[index] = file;
        
            this.imageSrc = URL.createObjectURL(file);        
        },

        async sendFacematch(liveness_id=false){

            const formData = new FormData();
            let localStorageName;

            formData.append("image_a", this.photo.front)

            if(liveness_id){
                formData.append("liveness_id", localStorage.getItem('liveness_id'))
                localStorageName = 'face_match_liveness_id'
                this.bestFrameCaptured = true;
            }else{
                formData.append("image_b", this.photo.people)
                localStorageName = 'face_match_photo_people'

            }

            const response = await FacematchService.postFacematch(formData)

            if(!response.error){
                localStorage.setItem(localStorageName, response.data.RowKey)
                return;
            }
            
            const msg = response.data.response.data?.mensagem ? response.data.response.data.mensagem : 'Algo não saiu como esperado. Tente novamente mais tarde.'
            this.$snackbar.add({ type: 'error',  text: msg})

        },

        async sendCpf(){
            await this.ActionLoadingChange();
            await this.getBackgroundCheck(this.cpf);
            await this.ActionLoadingChange();

            this.redirectResultPage();
        },

        async sendOCR(twoImages=true){
            
            let localStorageName = "ocr_people_id";
            const formData = new FormData();
            let image_front = this.photo.people;

            
            if(twoImages){
                localStorageName = "ocr_id"
                image_front = this.photo.front;
                formData.append("image_back", this.photo.back)
            }

            formData.append("image_front",image_front )


            const response = await OCRService.postOCR(formData)

            if(!response.error){
                localStorage.setItem(localStorageName, response.data.RowKey)
                return;
            }
            
            const msg = response.data.response.data?.mensagem ? response.data.response.data.mensagem : 'Algo não saiu como esperado. Tente novamente mais tarde.'
            this.$snackbar.add({ type: 'error',  text: msg})

        },

        async getBackgroundCheck(cpf){
            cpf = cpf.replace(/[^0-9 ]/g, "")
            const response = await BackgroundcheckService.getBackgroundcheck(cpf)

            if(!response.error){
                localStorage.setItem("cpf", response.data.cpf)    
                return;
            }
            
            const msg = response.data.response.data?.mensagem ? response.data.response.data.mensagem : 'Algo não saiu como esperado. Tente novamente mais tarde.'
            this.$snackbar.add({ type: 'error',  text: msg})
        },

        async sendResult(){
            const data = {
                "liveness_id": localStorage.getItem('liveness_id'),
                "cpf": localStorage.getItem('cpf'),
                "face_match_liveness_id": localStorage.getItem('face_match_liveness_id'),
                "face_match_photo_people": localStorage.getItem('face_match_photo_people'),
                "ocr_id": localStorage.getItem('ocr_id'),
                "ocr_people_id": localStorage.getItem("ocr_people_id")
            }
            const response =  await ResultService.sendResult(data)
            
            if(!response.error){
                this.result = response.data.id
                return
            }

            const msg = response.data.response.data?.mensagem ? response.data.response.data.mensagem : 'Algo não saiu como esperado. Tente novamente mais tarde.'
            this.$snackbar.add({ type: 'error',  text: msg})
        },

        nextAvailable(){
            const hashmap = ["front", "back", "people"];

            this.imageSrc = '';

            return this.photo[hashmap[this.step-1]] != '' && !this.fieldCpf;
        },

        async redirectResultPage(){
            await this.sendResult();
            this.$router.push(`/onboarding/result/${this.result}`)
        },
        

        nextStep(){
            this.step += 1;

            const steps = [this.stepOne, this.stepTwo, this.stepThree, this.stepFinal];

            return steps[this.step - 1]();
        }

    }
}