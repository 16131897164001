import SecundaryButton from '../../../components/buttons/SecundaryButton.vue';
import ButtonReturn from '../../../components/buttons/ButtonReturn.vue';
import CameraVideo from '../../../components/CameraVideo.vue';
import InputFileCamera from "@/components/inputs/InputFileCamera.vue"
import FacematchService from '@/services/FacematchService';
import InputText from '@/components/inputs/InputText.vue';
import { mapActions } from 'vuex';
import ConfirmButton from '../../../components/buttons/ConfirmButton.vue'

export default {
    name: 'OCRCamera',
    components: {
        SecundaryButton,
        ButtonReturn,
        CameraVideo,
        InputFileCamera,
        InputText,
        ConfirmButton
    },

    data() {
        return {
            photo: {
                image_a: '',
                image_b: '',
            },
            step: 1,
            stepTexts: {
                title: "Primeira foto para facematch!",
                documentText: "Tire uma foto sua.",
            },

        }
    },

    methods: {
        ...mapActions('loading', ['ActionLoadingChange']),

        async stepOne() {
            this.step = 1
            this.stepTexts.title = "Primeira foto para facematch!";
            this.stepTexts.documentText = "Tire uma foto sua.";
            
        },
        async stepTwo() {
            this.step = 2
            this.stepTexts.title = "Segunda foto para facematch!";
            this.stepTexts.documentText = "Tire uma foto sua ou de outra pessoa para comparação";
        },

        async stepFinal(){
            await this.ActionLoadingChange();
            await this.sendFacematch();
            await this.ActionLoadingChange();
            this.redirectResultPage();
        },

        readFile(file, index){
            this.photo[index] = file;
        
            this.imageSrc = URL.createObjectURL(file);        
        },

        async sendFacematch(){

            const formData = new FormData();

            formData.append("image_a", this.photo.image_a)
            formData.append("image_b", this.photo.image_b)


            const response = await FacematchService.postFacematch(formData)

            if(!response.error){
                localStorage.setItem('face_match_photo_people', response.data.RowKey)
                return;
            }
            
            const msg = response.data.response.data?.mensagem ? response.data.response.data.mensagem : 'Algo não saiu como esperado. Tente novamente mais tarde.'
            this.$snackbar.add({ type: 'error',  text: msg})

        },

        nextAvailable(){
            const hashmap = ["image_a", "image_b"];

            this.imageSrc = '';

            return this.photo[hashmap[this.step-1]] != '' 
        },

        async redirectResultPage(){
            this.$router.push(`/reconhecimentofacialresultado`)
        },
        

        nextStep(){
            this.step += 1;

            const steps = [this.stepOne, this.stepTwo, this.stepFinal];

            return steps[this.step - 1]();
        }

    }
}