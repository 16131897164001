<div>
        <div class="card" @click.prevent="show.ocr = !show.ocr">
            <div class="desc">
                <h4 v-show="show.ocr">{{title}} <span class="close"></span></h4>
                <h4 v-show="!show.ocr">{{title}} <span class="open"></span></h4>
                <div class="message-result-success" v-if="percent >= 100">
                    <p>Validação efetuada com sucesso!</p>
                </div>
            </div>
        </div>

        <Transition>
            <div v-show="show.ocr">
                <div class="group-items">
                    <k-progress v-if="percent != 100" status="success" type="line" :percent="percent">
                    </k-progress>
                    <p class="result-item">
                        CPF:
                        <b>{{
                            $filters.mask.cpf(ocr.cpf)
                            }}</b>
                    </p>
                    
                    <p class="result-item">
                        ID Requisição:
                        <b>{{ id }}</b>
                    </p>
                    <div class="row2">

                        <p class="result-item">
                            <b>Primeira Imagem:</b>

                            <img :src="ocr.image_front">

                        </p>

                        <p class="result-item" v-if="ocr?.image_back != null">
                            <b>Segunda Imagem:</b>

                            <img :src="ocr.image_back">

                        </p>



                    </div>

                    <div class="row1">


                        <div class="row1-doc">
                            <h3>Documento Frente</h3>
                            <span class="item">
                                <strong v-if="!front.show.text" @click="front.show.text = !front.show.text">Texto &#9658
                                </strong>
                                <strong v-if="front.show.text" @click="front.show.text = !front.show.text">Texto &#9660
                                </strong>
                                <p v-if="front.show.text">{{ocr.ocr.text?.[front.id]?.[0] }}</p>
                            </span>

                            <span class="item">
                                <strong v-if="!front.show.labels" @click="front.show.labels = !front.show.labels">Labels
                                    &#9658 </strong>
                                <strong v-if="front.show.labels" @click="front.show.labels = !front.show.labels">Labels
                                    &#9660 </strong>
                                <ul class="list" v-if="front.show.labels">
                                    <li v-for="(label,key) in ocr.ocr.labels?.[front.id]" :key=key>{{label}} </li>
                                </ul>
                            </span>

                            <span class="item">
                                <strong v-if="!front.show.faces" @click="front.show.faces = !front.show.faces">Faces
                                    &#9658
                                </strong>
                                <strong v-if="front.show.faces" @click="front.show.faces = !front.show.faces">Faces
                                    &#9660
                                </strong>
                                <ul class="list" v-if="front.show.faces">
                                    <li v-for="(face,key) in ocr.ocr.faces?.[front.id]?.[0]" :key=key>{{face}} </li>
                                </ul>
                            </span>
                        </div>

                        <div class="row1-doc" v-if="ocr?.image_back != null">
                            <h3>Documento Verso</h3>
                            <span class="item">
                                <strong v-if="!back.show.text" @click="back.show.text = !back.show.text">Texto &#9658
                                </strong>
                                <strong v-if="back.show.text" @click="back.show.text = !back.show.text">Texto &#9660
                                </strong>
                                <p v-if="back.show.text">{{ocr.ocr.text?.[back.id]?.[0] }}</p>
                            </span>


                            <span class="item">
                                <strong v-if="!back.show.labels" @click="back.show.labels = !back.show.labels">Labels
                                    &#9658
                                </strong>
                                <strong v-if="back.show.labels" @click="back.show.labels = !back.show.labels">Labels
                                    &#9660
                                </strong>
                                <li class="list" v-if="back.show.labels">
                                    <ul v-for="(label,key) in ocr.ocr.labels?.[back.id]" :key=key>{{label}} </ul>
                                </li>
                            </span>

                            <span class="item">
                                <strong v-if="!back.show.faces" @click="back.show.faces = !back.show.faces">Faces &#9658
                                </strong>
                                <strong v-if="back.show.faces" @click="back.show.faces = !back.show.faces">Faces &#9660
                                </strong>
                                <li class="list" v-if="back.show.faces">
                                    <ul v-for="(face,key) in ocr.ocr.faces?.[back.id]?.[0]" :key=key>{{face}} </ul>
                                </li>
                            </span>
                        </div>
                    </div>

                </div>
                <!-- <PrimaryButton name="Refazer Validação" pathRedirect="/" v-if="percent >= 100" /> -->
            </div>
        </Transition>
</div>