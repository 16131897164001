<template>
    <div class="item-form">
      <label>{{ label }}</label>
      <input  
      v-if="mask"
      v-mask="mask"
      :readonly="readonly"
      :class="{'border-error': errors.length != 0}" 
      :type="type" :min="min" :max="max"
      name="" id="" 
      :value="modelValue" @input="updateValue" />

      <input  
      v-if="!mask"
      :readonly="readonly"
      :class="{'border-error': errors.length != 0}" 
      :type="type" :min="min" :max="max"
      name="" id="" 
      :value="modelValue" @input="updateValue" />
      
      
    </div>
  </template>
  <script>
  
  export default {
    name: "InputText",
    props: {
      label: String,
      modelValue: {
        default:"",
      },
      readonly:{
        type: Boolean,
        default: false
      },
      min:{
        default:false
      },
      max:{
        default:false
      },
      type:{
        default:'text'
      },
      mask:{
        required: false,
        default:false,
      },
      errors: {
        default: [],
      },
    },
    
    methods: {
      updateValue(event) {
        this.$emit("update:modelValue", event.target.value);
      },
      getAtribbutes(){
        return {
          id:"",
          name: "",
          value: this.modelValue,
          type: 'text'
        }
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .item-form {
    display: grid;
    gap: 0.5rem;
    margin-top: 1rem;
    label{
      font-family: 'Lato';
    }

  }
  
  .error-message {
    display: flex;
    height: 0px;
    color: red;
  }
  
  .border-error{
    border-color: rgb(223, 11, 11);
  }
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  </style>
  