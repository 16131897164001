<template>
    <button @click="redirect()"  class="btn-return" title="Voltar">
        <img src="../../../public/assets/images/arrow-left.svg" alt="Voltar para tela anterior">
    </button>
</template>

<script>
export default {
    name: 'ButtonReturn',
    props: {
        pathRedirect: String,
    },

    methods: {
        redirect() {
            window.location.href = this.pathRedirect
        }
    }
}
</script>