<div>
    <div class="screenvalidation">
        <div class="center">
            <ButtonReturn pathRedirect="/onboarding"/>
            <h3 class="top">Prova de vida</h3>

            <VideoRecord @send="sendForm($event)"/>

            <SecundaryButton pathRedirect="/onboarding" name="Voltar"/>
        </div>
    </div>
</div>