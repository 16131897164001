<template>
    <div class="confirm">
        <button class="btn-confirm">
            <img src="../../assets/images/icon-check.svg" alt="">
            {{ name }}
        </button>
        <a href="" >
            Refazer validação
        </a>
    </div>
</template>

<script>
export default {
    name: 'ConfirmButton',
    props: {
        name: String
    },
}
</script>
