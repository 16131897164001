<div>
    <div class="center">
        <div class="head">
            <ButtonReturn pathRedirect="/" />
            <h3>Validação por video</h3>
        </div>
        <div class="content">
            <img class="illustration" src="./.../../../../../../public/assets/images/ilustration-facematch.svg" alt="Ícone Reconhecimento Facial" >
            <p>
                Clique em começar e siga as instruções da tela.
            </p>
            <h5>Importante</h5>
            <p>
                Para a co validação, é necessário estar em um local bem iluminado e sem acessórios que possam
                prejudicar a visualização
            </p>
            <PrimaryButton name="Avançar" pathRedirect="/reconhecimentofacialvalidacao" />
        </div>
    </div>
</div>