<div>
    <div class="center">
        <router-link to="/ocrdocumentos">
            <ButtonReturn pathRedirect="/"/>
        </router-link>
        <p>Tire uma foto da sua CNH:</p>
        <ul>
            <li>
                Certifique que a foto esteja nítida.
            </li>
            <li>
                Prefira locais bem iluminados.
            </li>
        </ul>
        <div class="images">
            <div>
                <div class="img-item">
                    <img src="../../../../assets/images/doc-cnh.jpg" alt="Frente da CNH">
                    <span class="border-top-left "></span>
                    <span class="border-top-right "></span>
                    <span class="border-bottom-left "></span>
                    <span class="border-bottom-right "></span>
                </div>
                <h4 class=""></h4>

                <label class="secundarybutton">
                    <img src="../../../../assets/images/icon-camera.svg" alt="Ícone câmera">
                    <input type="file" accept="image/*;capture=camera">
                </label>
            </div>
        </div>
        <PrimaryButton name="Enviar" pathRedirect="/resultado" />
    </div>
    <OcrDocumentsCNHValidation v-if="show_modal" :closeFn="closeForm"/>
</div>