<div class="center">
    <div class="head">
        <ButtonReturn pathRedirect="/"/>
        <h3>Onboarding</h3>
    </div>
    <div class="content">
        <img src="../../../../public/assets/images/validation-ok.svg" alt="Ilustração de Validação Ok" class="illustration">
        <h3>Validação efetuada com sucesso!</h3>
        <p>
            <p>Agora nos envie algumas fotos do seu <b>Documento Oficial com Foto</b>.</p>
            <p>Clique em "Começar" e siga as instruções na tela.</p>
        </p>
        <PrimaryButton name="Começar" pathRedirect="/onboarding/ocr/camera"/>
    </div>
    
</div>