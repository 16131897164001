import PrimaryButton from '../../../components/buttons/PrimaryButton.vue'
import ButtonReturn from '../../../components/buttons/ButtonReturn.vue'

export default {
    name: 'OnboardingIndex',
    components: {
        PrimaryButton,
        ButtonReturn
    },

    mounted(){
        localStorage.removeItem('liveness_id');
        localStorage.removeItem('cpf');
        localStorage.removeItem('face_match_liveness_id');
        localStorage.removeItem('face_match_photo_people');
        localStorage.removeItem('ocr_id');
        localStorage.removeItem("ocr_people_id");
        localStorage.removeItem("voice_recognition");

    },
}