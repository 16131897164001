<template>
    <div class="ilustration doc-verse">
        <img src="../../../public/assets/images/doc-verso.png" alt="Documento Oficial com Foto Identidade Verso">
    </div>
</template>
<script>
export default {
    name: 'DocVerse',
}

</script>
<style lang="scss" scoped>
.ilustration {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    img {
        max-width: 80%;
    }
}
</style>