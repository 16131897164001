import InputText from '../../../components/inputs/InputText.vue'
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue'
import ButtonReturn from '../../../components/buttons/ButtonReturn.vue'
import {mask} from 'vue-the-mask'
import BackgroundcheckService from '@/services/BackgroundcheckService'
import { mapActions } from 'vuex';

export default {
    name: 'BackgroundCheckIndex',
    components: {
        InputText,
        PrimaryButton,
        ButtonReturn,
    },

    data () {
        return {
            form:{
                cpf:""
            }
        }
    },

    methods: {
        ...mapActions('loading', ['ActionLoadingChange']),
        ...mapActions('pagesData', ['ActionSetBackgroundCheck']),


        async sendForm() {

            await this.ActionLoadingChange();
            const cpf = this.form.cpf.replace(/[^0-9 ]/g, "")
            const response = await BackgroundcheckService.getBackgroundcheck(cpf)

            if(!response.error){
                await this.ActionLoadingChange();
                this.ActionSetBackgroundCheck(response.data)

                this.$router.push({ name: 'backgroundcheckconfirm'})
                return;
            }
            
            await this.ActionLoadingChange();
            const msg = response.data.response.data?.mensagem ? response.data.response.data.mensagem : 'Algo não saiu como esperado. Tente novamente mais tarde.'
            this.$snackbar.add({ type: 'error',  text: msg})

        },

      },
    directives: {mask}
}