import PrimaryButton from '../../../components/buttons/PrimaryButton.vue'
import ButtonReturn from '../../../components/buttons/ButtonReturn.vue'
import store from '@/store'

export default {
    name: 'BackgroundCheckConfirm',
    components: {
        ButtonReturn,
        PrimaryButton,
    },
    data(){
        return {
            backgroundCheck:{}
        }
    },
    beforeMount(){
        this.backgroundCheck = store.getters['pagesData/backgroundCheck']
    }
}