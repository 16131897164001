<template>
  <input
    type="checkbox"
    name="accept"
    id="accept"
    :checked="isChecked"
    :value="value"
    @change="updateInput"
  />
  <label for="accept"></label>
</template>

<script>
export default {
  name: "CheckBox",

  data() {
    return {
      isChecked: false,
    };
  },

  model: {
    prop: "modelValue",
    event: "change",
  },

  props: {
    value: { type: String },
    modelValue: { default: "" },
    trueValue: { default: true },
    falseValue: { default: false },
    checked: {default:false},
  },

  beforeMount(){
    this.isChecked = this.checked
  },

  methods: {
    updateInput(event) {
      this.isChecked = event.target.checked;
      this.$emit("update:modelValue", event.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="checkbox"] {
  display: none;
}
label {
  display: block;
  position: relative;
  height: 1rem;
  width: 1rem;
  min-width: 1rem;
  border: var(--blue-dark) solid 0.15rem;
  border-radius: 0.2rem;
}
label:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0.25rem;
  border-bottom: var(--white) solid 0.18rem;
  border-right: var(--white) solid 0.18rem;
  height: 0.6rem;
  width: 0.3rem;
  transform: rotate(45deg);
}
input[type="checkbox"]:checked ~ label {
  background-color: var(--blue-dark);
}
</style>
