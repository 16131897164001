<div>
    <div class="center">
        <div class="head">
            <h3>Background Check</h3>
        </div>
        <div class="message-result">
            <img src="../../../../public/assets/images/icon-validado.svg" alt="Icone Resultado">
            <h3>Validação efetuada com sucesso!</h3>
        </div>
        <h5>Processo Concluído</h5>
        <div class="group-items">
            <p class="result-item">
                <b>CPF:</b>
                <span>{{$filters.mask.cpf(backgroundCheck.cpf)}}</span>
            </p>
            <p class="result-item">
                <b>Nome:</b>
                <span>{{backgroundCheck.name}}</span>
            </p>
            <div class="row2">
                <p class="result-item">
                    <b>Situação:</b>
                    <span>{{backgroundCheck.situation}}</span>
                </p>
                <p class="result-item">
                    <b>Data Nascimento:</b>
                    <span>{{$filters.mask.format_date(backgroundCheck.birth_date)}}</span>
                </p>
            </div>
        </div>
        <PrimaryButton name="Refazer Validação" pathRedirect="/" />
    </div>
</div>