
    <div class="center" >
        <div class="head">
            <ButtonReturn pathRedirect="/"/>
            <h3>Reconhecimento Facial</h3>
        </div>
        <div class="content">
           
            <h5>
                {{stepTexts.title}}
            </h5>
            <p>{{stepTexts.documentText}}</p>
            <img src="../../../../public/assets/images/ilustration-photo.svg" alt="Ilustração de uma foto" class="illustration">
            <img  :src="imageSrc" style="width:300px;display: block; margin:auto;"/>
        </div>

  
      <div style="display:grid">
        
        <InputFileCamera v-if="step == 1" @file="readFile($event, 'image_a')"/>
        <InputFileCamera v-if="step == 2"  @file="readFile($event, 'image_b')"/>


        <button  @click="nextStep" class="btn-primary" v-if="step < 3 && nextAvailable()" >Avançar</button>

        </div>

    </div>