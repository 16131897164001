import { createStore } from 'vuex'
import loading from "./modules/loading";
import pagesData from "./modules/pagesData";

const store = createStore({
    modules:{
        loading,
        pagesData
    }
})

export default store;

