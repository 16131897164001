<template>
  <div>

  <p>Clique na Camera abaixo para realizar a captura da foto</p>

    <label class="btn-primary" for="camera">
      <img src="../../../public/assets/images/icon-camera-bco.svg" alt="" />
    </label>
    <input
      type="file"
      accept="image/*"
      capture
      id="camera"
      hidden
      :value="modelValue"
      @input="updateValue"
    />
  </div>
</template>
<script>
export default {
  name: "InputFileCamera",
  props: {
    label: String,
    modelValue: {
      default: "",
    },
  },

  methods: {
    updateValue(event) {
      this.$emit("file", event.target.files[0]);
    },
  },
};
</script>
<style lang="scss" scoped>
p{
  text-align: center;
}
.btn-primary{
  width: 8rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto 0;

}
</style>
