<template>
    <div class="screen-modal">
        <div id="preloader">
            <div id="loader">
            </div>
            <img src="../../assets/images/logo-white.svg" alt="Carregando">
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoadingScreen',
}
</script>

<style lang="scss" scoped>
#preloader {
    background-color: #00000086;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    img{
        position: absolute;
        height: 2.5rem;
        left: 50%;
        top: 50%;
        translate: -50% -50%;
        -moz-translate: -50% -50%;
        -ms-translate: -50% -50%;
    }
}
#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: var(--blue);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>