<template>
    <div class="card">
                    <img :src="linkimage" class="icon-card" alt="ícone card">
                <h4>{{name}}</h4>
    </div>
    
</template>

<script>
export default {
    name:'CardHome',
    props:{
        name: String,
        linkimage: String,
    }
}
</script>

<style lang="scss" scoped>
.card{
    padding: 1rem;
    background-color: var(--white-light);
    border-radius: .5rem;
    height: calc(100% - 2rem);
    transition: all .2s;
    &:hover{
        box-shadow: 0 0 .3rem rgba(2, 7, 82, 0.116);
    }
    img{
        margin: 0 0 2rem auto;
        display: block;
    }
    h4{
        text-align: start;
        max-width: 150px;
        margin: 0;
    }

    .status{
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
        top: 1rem;
        right: 1rem;
        display: inline-block;
        height: 1rem;
        width: 1rem;
    }
    .validated{
        background-image: url('../../../public/assets/images/icon-validado.svg');
    }
    .analysis{
        background-image: url('../../assets/images/icon-analysis.svg');
    }
    .problem{
        background-image: url('../../assets/images/icon-problem.svg');

    }
}

</style>