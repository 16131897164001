<div>
    <div class="center">
        <div class="head">
            <h3>Prova de Vida</h3>
        </div>
        <div class="message-result" v-if="percent >= 100">
            <img src="../../../../public/assets/images/icon-validado.svg" alt="Icone Resultado">
            <h3>Validação efetuada com sucesso!</h3>
        </div>
        <div class="group-items">
            <h5>Resultado</h5>

            <k-progress v-if="percent != 100" status="success" type="line" :percent="percent">
            </k-progress>

            <div class="row2">
                <p class="result-item">
                    <b>Data:</b>
                    <span>{{$filters.mask.format_date(liveness.created_at)}}</span>
                </p>
                <p class="result-item">
                    <b>Horário:</b>
                    <span>{{$filters.mask.format_hour(liveness.modified_at)}}</span>
                </p>
            </div>
            <div class="row2">
                <p class="result-item">
                    <b>Piscadas:</b>
                    <easy-spinner v-if="analyseStatus(null, liveness.blinks_status) == 'loading' " />


                    <span
                        v-if="analyseStatus(null, liveness.blinks_status) != 'loading'">{{analyseStatus(liveness.blinks, liveness.blinks_status)}}</span>


                    <!-- <span  v-if="liveness.blinks < liveness.blinks_expected && liveness.blinks_status == 'processed'">{{`${liveness.blinks/liveness.blinks_expected*100}%` }}</span>
                    <span v-if="liveness.blinks >= liveness.blinks_expected && liveness.blinks_status == 'processed'">{{`100%` }}</span> -->
                </p>
                <p class="result-item">
                    <b>Movimento de boca:</b>
                    <easy-spinner v-if="analyseStatus(null, liveness.mouth_status) == 'loading' " />

                    <span
                        v-if="analyseStatus(null, liveness.mouth_status) != 'loading'">{{analyseStatus(liveness.mouth, liveness.mouth_status)}}</span>
                    <!-- 
                    <span  v-if="liveness.mouth < liveness.mouth_expected && liveness.mouth_status == 'processed' ">{{`${liveness.mouth/liveness.mouth_expected*100}%` }}</span>
                    <span v-if="liveness.mouth >= liveness.mouth_expected && liveness.mouth_status == 'processed'">{{`100%` }}</span> -->

                </p>
            </div>

            <div class="row2">
                <p class="result-item">
                    <b>Mesma face:</b>
                    <easy-spinner v-if="analyseStatus(null, liveness.same_face_status) == 'loading' " />


                    <span
                        v-if="analyseStatus(null, liveness.same_face_status) != 'loading' && liveness.same_face ">Verdadeiro</span>
                    <span
                        v-if="analyseStatus(null, liveness.same_face_status) != 'loading' && !liveness.same_face ">Falso</span>
                </p>
                <p class="result-item">
                    <b>Houve mudanças de cenário:</b>
                    <easy-spinner v-if="analyseStatus(null, liveness.scene_status) == 'loading' " />


                    <span
                        v-if="analyseStatus(null, liveness.scene_status) != 'loading' && liveness.scene ">Verdadeiro</span>
                    <span
                        v-if="analyseStatus(null, liveness.scene_status) != 'loading' && !liveness.scene ">Falso</span>

                </p>

            </div>

            <div class="row2">
                <p class="result-item">
                    <b>Reconhecimento Voz:</b>
                    <easy-spinner v-if="analyseStatus(null, liveness.voice_recognition_status) == 'loading' " />

                    <span
                        v-if="analyseStatus(null, liveness.voice_recognition_status) != 'loading'">{{analyseStatus(liveness.voice_recognition, liveness.voice_recognition_status)}}</span>
                </p>

                <p class="result-item">
                    <b>Palavras esperadas:</b>
                    <easy-spinner v-if="analyseStatus(null, liveness.voice_recognition_status) == 'loading' " />

                    <span
                        v-if="liveness.voice_recognition_expected && liveness.voice_recognition_status == 'processed'">{{liveness.voice_recognition_expected}}</span>
                    <span
                        v-if="analyseStatus(null, liveness.voice_recognition_status) != 'loading'">{{analyseStatus(liveness.voice_recognition_status, liveness.voice_recognition_expected)}}</span>

                </p>
            </div>

            <p class="result-item">
                <b>Multiplas Faces:</b>
                <easy-spinner v-if="analyseStatus(null, liveness.multiple_faces_status) == 'loading' " />

                <span
                    v-if="analyseStatus(null, liveness.multiple_faces_status) != 'loading'">{{analyseStatus(liveness.multiple_faces, liveness.multiple_faces_status)}}</span>

            </p>

            <div class="row2">
                <p class="result-item">
                    <b>Melhor frame:</b>
                    <easy-spinner v-if="analyseStatus(null, liveness.best_frame_status) == 'loading' " />

                    <img v-if="liveness.best_frame_status == 'processed' " :src="liveness.best_frame"
                        alt="Best frame" />
                    <span
                        v-if="analyseStatus(null, liveness.best_frame_status) != 'loading' && liveness.best_frame_status != 'processed' ">{{analyseStatus(liveness.best_frame, liveness.best_frame_status)}}</span>
                </p>

                <p class="result-item">
                    <b>Vídeo:</b>
                    <easy-spinner v-if="analyseStatus(null, liveness.best_frame_status) == 'loading' " />

                    <video :src="liveness?.video"  controls></video>
                </p>

            </div>


            <p class="result-item">
                <b>ID Requisição:</b>
                <span>{{liveness.RowKey}}</span>
            </p>
        </div>
        <PrimaryButton name="Refazer Validação" pathRedirect="/" v-if="percent >= 100" />
    </div>
</div>