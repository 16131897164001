<template>
    <div id="app">
        <VideoRecord @send="sendForm($event)"/>
    </div>
</template>
  
<script>
import LivenessService from "@/services/LivenessService";
import VideoRecord from "../../components/VideoRecord.vue";

export default {
    name: "VideoSelfie",
    components: {
        VideoRecord,
    },
    methods:{
        async sendForm(data){
            const formData = new FormData();

            formData.append('video_path', data.file);
            formData.append('mouth_quantity', 4);
            formData.append('blinks_quantity', 4);
            formData.append('best_frame', true);
            formData.append('scene', true);
            formData.append('faces', true);
            formData.append('voice_recognition', data.words.join(" "));

            console.log('aqui',data);


            const response = await LivenessService.sendLiveness(formData);

            // const teste = await LivenessService.getLiveness(response.data.liveness_id)

            localStorage.set('liveness_id', response.data.RownKey)
            localStorage.setItem('voice_recognition',data.words.join(" "))


        }
    }
};
</script>

  