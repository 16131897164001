import CardHome from '../../components/cards/CardHome.vue'
import ButtonReturn from '../../components/buttons/ButtonReturn.vue'
import PrimaryButton from '../../components/buttons/PrimaryButton.vue'

export default{
    name: 'OcrDocuments',
    components: {
        CardHome,
        ButtonReturn,
        PrimaryButton
    }
}