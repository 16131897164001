
        <div class="card"  @click.prevent="show.liveness = !show.liveness">
            <div class="desc">
                <h4 v-show="show.liveness">Prova de Vida  <span class="close"></span></h4>
                <h4 v-show="!show.liveness">Prova de Vida <span class="open"></span></h4>
                <div class="message-result-success" v-if="percent >= 100">
                    <p>Validação efetuada com sucesso!</p>
                </div>
            </div>
        </div>

        <Transition>
            <div v-show="show.liveness">
                <div class="group-items">
                    <k-progress v-if="percent != 100" status="success" type="line" :percent="percent">
                    </k-progress>

                    <div class="row2">
                        <p class="result-item">
                            Data:
                            <b>{{
                                $filters.mask.format_date(liveness.created_at)
                                }}</b>
                        </p>
                        <p class="result-item">
                            Horário:
                            <b>{{
                                $filters.mask.format_hour(liveness.created_at)
                                }}</b>
                        </p>
                    </div>
                    <div class="row2">
                        <p class="result-item">
                            Piscadas:
                            <easy-spinner v-if="
                    analyseStatus(null, liveness.blinks_status) == 'loading'
                  " />

                            <b v-if="
                    analyseStatus(null, liveness.blinks_status) != 'loading'
                  ">{{
                                analyseStatus(liveness.blinks, liveness.blinks_status)
                                }}</b>

                            <!-- <b  v-if="liveness.blinks < liveness.blinks_expected && liveness.blinks_status == 'processed'">{{`${liveness.blinks/liveness.blinks_expected*100}%` }}</b>
                            <b v-if="liveness.blinks >= liveness.blinks_expected && liveness.blinks_status == 'processed'">{{`100%` }}</b> -->
                        </p>
                        <p class="result-item">
                            Movimento de boca:
                            <easy-spinner v-if="analyseStatus(null, liveness.mouth_status) == 'loading'" />

                            <b v-if="analyseStatus(null, liveness.mouth_status) != 'loading'">{{
                                analyseStatus(liveness.mouth, liveness.mouth_status)
                                }}</b>
                            <!-- 
                            <b  v-if="liveness.mouth < liveness.mouth_expected && liveness.mouth_status == 'processed' ">{{`${liveness.mouth/liveness.mouth_expected*100}%` }}</b>
                            <b v-if="liveness.mouth >= liveness.mouth_expected && liveness.mouth_status == 'processed'">{{`100%` }}</b> -->
                        </p>
                    </div>

                    <div class="row2">
                        <p class="result-item">
                            Mesma face:
                            <easy-spinner v-if="
                    analyseStatus(null, liveness.same_face_status) == 'loading'
                  " />

                            <b v-if="
                    analyseStatus(null, liveness.same_face_status) !=
                      'loading' && liveness.same_face
                  ">Verdadeiro</b>
                            <b v-if="
                    analyseStatus(null, liveness.same_face_status) !=
                      'loading' && !liveness.same_face
                  ">Falso</b>
                        </p>
                        <p class="result-item">
                            Houve mudanças de cenário:
                            <easy-spinner v-if="analyseStatus(null, liveness.scene_status) == 'loading'" />

                            <b v-if="
                    analyseStatus(null, liveness.scene_status) != 'loading' &&
                    liveness.scene
                  ">Verdadeiro</b>
                            <b v-if="
                    analyseStatus(null, liveness.scene_status) != 'loading' &&
                    !liveness.scene
                  ">Falso</b>
                        </p>
                    </div>

                    <div class="row2">
                        <p class="result-item">
                            Reconhecimento Voz:
                            <easy-spinner v-if="
                    analyseStatus(null, liveness.voice_recognition_status) ==
                    'loading'
                  " />

                            <b v-if="
                    analyseStatus(null, liveness.voice_recognition_status) !=
                    'loading'
                  ">{{
                                analyseStatus(
                                liveness.voice_recognition,
                                liveness.voice_recognition_status
                                )
                                }}</b>
                        </p>

                        <p class="result-item">
                            Palavras esperadas:
                            <easy-spinner v-if="
                    analyseStatus(null, liveness.voice_recognition_status) ==
                    'loading'
                  " />

                            <b v-if="
                    liveness.voice_recognition_expected &&
                    liveness.voice_recognition_status == 'processed'
                  ">{{ liveness.voice_recognition_expected }}</b>
                            <b v-if="
                    analyseStatus(null, liveness.voice_recognition_status) !=
                    'loading'
                  ">{{
                                analyseStatus(
                                liveness.voice_recognition_status,
                                liveness.voice_recognition_expected
                                )
                                }}</b>
                        </p>
                    </div>

                    <p class="result-item">
                        Multiplas Faces:
                        <easy-spinner v-if="
                  analyseStatus(null, liveness.multiple_faces_status) ==
                  'loading'
                " />

                        <b v-if="
                  analyseStatus(null, liveness.multiple_faces_status) !=
                  'loading'
                ">{{
                            analyseStatus(
                            liveness.multiple_faces,
                            liveness.multiple_faces_status
                            )
                            }}</b>
                    </p>

                    <div class="row2">
                        <p class="result-item">
                           <b>Melhor frame:</b> 
                            <easy-spinner v-if="
                    analyseStatus(null, liveness.best_frame_status) == 'loading'
                  " />

                            <img v-if="liveness.best_frame_status == 'processed'" :src="liveness.best_frame"
                                alt="Best frame" />
                            <b v-if="
                    analyseStatus(null, liveness.best_frame_status) !=
                      'loading' && liveness.best_frame_status != 'processed'
                  ">{{
                                analyseStatus(
                                liveness.best_frame,
                                liveness.best_frame_status
                                )
                                }}</b>
                        </p>

                        <p class="result-item">
                            <b>Vídeo:</b>
                            <easy-spinner v-if="
                    analyseStatus(null, liveness.best_frame_status) == 'loading'
                  " />

                            <video :src="liveness?.video" controls></video>
                        </p>
                    </div>

                    <p class="result-item">
                        ID Requisição:
                        <b>{{ id }}</b>
                    </p>
                </div>
            </div>
        </Transition>