import PrimaryButton from "../../../buttons/PrimaryButton.vue";
import store from "@/store";
import LivenessService from "@/services/LivenessService";

export default {
    name: "CardLivenessResult",
    components: {
        PrimaryButton,
    },
    props:{
        id:{
            required: true,
        }
    },
    data() {
        return {
            liveness: {},
            percent: 0,
            show: {
                liveness: false,
            },
        };
    },

    beforeMount() {
        this.liveness = store.getters["pagesData/liveness"];
        this.getData();

    },

    methods: {
        getData() {
            const setData = this.setData;
            const id = this.id;
                       
            const interval = setInterval(async function () {

                const video_process = await LivenessService.getLiveness(id);
                const status = ["processed", "error"]

                const processed_blink = status.includes(video_process.data.blinks_status);
                const processed_mouth = status.includes(video_process.data.mouth_status);
                const processed_multiple_face = status.includes(video_process.data.multiple_faces_status);
                    
                const processed_same_face = status.includes(video_process.data.same_face_status);
                    
                const processed_scene = status.includes(video_process.data.scene_status);
                const processed_voice = status.includes(video_process.data.voice_recognition_status);
                const best_frame = status.includes(video_process.data.best_frame_status);

                const processed = [
                    processed_blink,
                    processed_mouth,
                    processed_multiple_face,
                    processed_same_face,
                    processed_scene,
                    best_frame,
                    processed_voice,
                ];
                const count = {
                    true: 0
                };

                processed.forEach((element) => {
                    count[element] = (count[element] || 0) + 1;
                });

                const percent = Math.trunc((count[true] / processed.length) * 100);
                setData(video_process.data, percent);

                if (!processed.includes(false)) {
                    clearInterval(interval);
                }
            }, 2000);
        },

        analyseStatus(data, status) {
            const result = {
                processed: data,
                queued: "loading",
                pending: "loading",
                "processing ": "loading",
                error: "Não reconhecido",
                execption: "Algo não ocorreu como esperado",
                unknown: "Algo não ocorreu como esperado",
                not_requested: "Não requisitado",
            };

            return result[status];
        },

        setData(liveness, percent) {
            this.percent = percent;
            this.liveness = liveness;  
            this.liveness['voice_recognition_expected'] = localStorage.getItem('voice_recognition')       
        },
    },
};