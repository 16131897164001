import api from "./Api"

export default {
    async getBackgroundcheck(cpf) {
        const response = await api.get(`/background_check/${cpf}/`)
            .then((response) => response)
            .catch(err => err);
        return response;
    },
    
}