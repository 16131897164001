<div>
        <div class="card" @click.prevent="show.face_match = !show.face_match">
            <div class="desc">
                <h4 v-show="show.face_match">{{title}}   <span class="close"></span></h4>
                <h4 v-show="!show.face_match">{{title}} <span class="open"></span></h4>
                
                <div class="message-result-success" v-if="percent >= 100">
                    <p>Validação efetuada com sucesso!</p>
                </div>
            </div>
        </div>

        <Transition>
            <div v-show="show.face_match && id != null">
                <div class="group-items">
                    <k-progress v-if="percent != 100" status="success" type="line" :percent="percent">
                    </k-progress>

                    <div class="row2">
                        <p class="result-item">
                            Facematch:
                            <b v-if="face_match.face_match">Verdadeiro</b>
                            <b v-if="!face_match.face_match">Falso</b>
                        </p>

                    </div>
                    <div class="row2">

                        <p class="result-item">
                            <b>Primeira Imagem:</b>

                            <img :src="face_match.image_a">
                          
                        </p>

                        <p class="result-item">
                            <b>Segunda Imagem:</b>

                            <img :src="face_match.image_b">
                          
                        </p>

                    </div>

                    <div class="row2">
                        <p class="result-item">
                            Data:
                            <b>{{
                                $filters.mask.format_date(face_match.created_at)
                                }}</b>
                        </p>
                        <p class="result-item">
                            Horário:
                            <b>{{
                                $filters.mask.format_hour(face_match.created_at)
                                }}</b>
                        </p>
                    </div>

                    <p class="result-item">
                        ID Requisição:
                        <b>{{ id }}</b>
                    </p>
                </div>
                <!-- <PrimaryButton name="Refazer Validação" pathRedirect="/" v-if="percent >= 100" /> -->
            </div>

            
        </Transition>

        <Transition>
            <div v-show="show.face_match && typeFacematch == 1 && id == null">
                <h3>Resultado</h3>

                <div class="message-error-result">
                    <img src="../../../../assets/images/icon-problem.svg" alt="Icone Resultado" />
                    <h2>Algo não aconteceu como esperado!</h2>
                </div>

                <div class="group-items">
                    <p class="result-item">
                        ID Requisição:
                        <b>{{ id }}</b>
                    </p>
                </div>

            </div>

        </Transition>
</div>