<div class="center">
        <div class="head">
            <ButtonReturn pathRedirect="/onboarding" />
            <h3>Onboarding</h3>
        </div>

        <div class="content">
            <b>
                {{stepTexts.title}}
            </b>
            <p>{{stepTexts.documentText}}</p>

            <DocFront v-if="step == 1"  />
            <DocVerse v-if="step == 2"  />
            <DocSelfie v-if="step == 3"  />
        </div>

        <img :src="imageSrc" style="width:300px;display: block; margin:auto;" />

        <div v-if="fieldCpf" style="display:grid">
            <InputText label="Informe o CPF:" v-model="cpf" :mask="['###.###.###-##']" />

            <button style="margin-top:10px" @click="sendCpf" class="btn-primary">Enviar</button>
        </div>



        <div style="display:grid">

            <InputFileCamera v-if="step == 1" @file="readFile($event, 'front')" />
            <InputFileCamera v-if="step == 2" @file="readFile($event, 'back')" />
            <InputFileCamera v-if="step == 3" @file="readFile($event, 'people')" />

            <button @click="nextStep" class="btn-primary" v-if="step < 4 && nextAvailable()">Avançar</button>
            <!-- <button  @click="step -= 1" class="btn-primary" v-if="step > 1" >Voltar</button> -->

        </div>
</div>