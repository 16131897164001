import SecundaryButton from '../../../components/buttons/SecundaryButton.vue';
import ButtonReturn from '../../../components/buttons/ButtonReturn.vue';
import VideoRecord from '../../../components/VideoRecord.vue';
import ConfirmButton from '../../../components/buttons/ConfirmButton.vue'
import LivenessService from '@/services/LivenessService';
import { mapActions } from 'vuex';

export default {
    name: 'BackgroundCheckValidation',
    components: {
        SecundaryButton,
        ButtonReturn,
        VideoRecord,
        ConfirmButton,
    },

    data() {
        return {
            file: null,
            readySend: false,
        }
    },

    methods: {
        ...mapActions('loading', ['ActionLoadingChange']),
        ...mapActions('pagesData', ['ActionSetLiveness']),


        prepareSend(file) {
            this.readySend = true;
            this.file = file
        },
        async sendForm(data) {
            const formData = new FormData();

            formData.append('video_path', data.file);
            formData.append('mouth_quantity', 4);
            formData.append('blinks_quantity', 4);
            formData.append('best_frame', true);
            formData.append('scene', true);
            formData.append('faces', true);
            formData.append('voice_recognition', data.words.join(" "));


            await this.ActionLoadingChange();

            const response = await LivenessService.sendLiveness(formData);
            localStorage.setItem('liveness_id', response.data.RowKey)
            localStorage.setItem('voice_recognition',data.words.join(" "))

            this.redirectToSuccess(response.data);

        },

        async redirectToSuccess(data){
            await this.ActionLoadingChange();
            this.ActionSetLiveness(data);
            this.$router.push({name:'livenessconfirm'})


        }
    }
}