<template>
    <div class="ilustration doc-selfie">
        <img src="../../assets//images/img-selfie-doc.png" alt="Documento Oficial com Foto Identidade Verso">
    </div>
</template>
<script>
export default {
    name: 'DocSelfie',
}

</script>
<style lang="scss" scoped>
.ilustration {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    img {
        width: 15rem;
        margin: 1rem 0;
    }
}
</style>