<template>
  <div class=" center">
    <div class="group-video">
      <span v-if="timeStartRec > 0" class="area-time-1" style="position:absolute">
        <p>O vídeo começará em:</p>
        <br>
        <p class="count" >
          {{`${timeStartRec}`}}
        </p>
      </span>

      <video class="video" autoplay playsinline ref="video"></video>
    </div>
    <span class="area-time">
      <img src="../../public/assets/images/icon-time.svg" alt="Ícone Time">
      {{`00:0${time}`}}</span>
    <span class="area-into">
      <h5>Diga em voz alta as palavras abaixo</h5>
      <h2 v-if="timeStartRec == 0">{{wordNow}}</h2>
      <p v-if="timeStartRec != 0">{{`Quando vídeo começar leia as seguintes palavras em voz alta`}}</p>
    </span>
  </div>
</template>
 

<script>
import RecordRTC from "recordrtc";

export default {
  name: "VideoRecord",
  data() {
    return {
      recorder: null,
      disabled: false,
      disabledRecord: false,
      file: null,
      time: 12,
      timeStartRec:3,
      randomWords:['Maça', 'Arroz', 'Vida', 'Roupa', 'Jogo', 'Cozinha', 'Brasil', 'Chocolate', 'Teclado'],
      randomWordsSaid: [],
      wordNow: "",
      intervalChangeWord: null,
    };
  },
  beforeMount() { 
    this.willStartRec();
  },
  

  methods: {

    startRec() {
      this.disabled = true;
      this.captureCamera(camera => {
        const video = this.$refs["video"];
        video.muted = false;
        video.srcObject = camera;
        this.recorder = RecordRTC(camera, {
          type: "video",
          mimeType: "video/webm\;codecs=vp9", // eslint-disable-line
        });
        this.recorder.setRecordingDuration(this.time*1000).onRecordingStopped(this.stopRecordingCallback);

        this.recorder.startRecording();

        // release camera on stopRecording
        this.recorder.camera = camera;
        this.disabled = true;

        this.startTime()

      });
    },

    stopRec() {
      this.recorder.stopRecording(this.stopRecordingCallback);
    },

    changeWord(){

      const choseRandomWord = this.choseRandomWord

      this.intervalChangeWord = setInterval( async function() {
                choseRandomWord()
            }, 3000);  
  
    },

    stopChangeWord(){
      clearInterval(this.intervalChangeWord)
    },

    choseRandomWord(){
      const word = this.randomWords[Math.floor(Math.random()*this.randomWords.length)]

      if(this.randomWordsSaid.includes(word)){
        this.choseRandomWord();
        return;
      }
      this.randomWordsSaid.push(word);

      this.wordNow = word;
    },

    willStartRec(){
      const decraseTime = this.decraseTimeStartRec

      const startRec = this.startRec
      

      const interval = setInterval( async function() {
                if(decraseTime() == 0){
                    clearInterval(interval)
                    startRec()
                }
            }, 1000);    
    },

    decraseTimeStartRec(){
      return this.timeStartRec -= 1
    },
  

    startTime(){
      const decraseTime = this.decraseStartTime
      const stopChangeWord = this.stopChangeWord;

      const interval = setInterval(async function () {
        if (decraseTime() == 0) {
          clearInterval(interval)
          stopChangeWord();

        }
      }, 1000);
      if(decraseTime != 0){
        this.changeWord();

      }
    },

    decraseStartTime(){
      return this.time -= 1
    },
  
    captureCamera(callback) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(function (camera) {
          callback(camera);
        })
        .catch(function (error) {
          alert("Unable to capture your camera. Please check console logs.");
          console.error(error);
        });
    },

    stopRecordingCallback() {
      this.disabled = false;
      const video = this.$refs["video"];
      video.src = video.srcObject = null;
      video.muted = false;
      video.volume = 1;
      video.src = URL.createObjectURL(this.recorder.getBlob());

      const recorderBlob = this.recorder.getBlob();
      this.file = new File([recorderBlob], "record", { type: "video/mp4" });

      const data = {
        "file": this.file,
        "words": this.randomWordsSaid
      }

      this.$emit("send", data);
      this.disabledRecord = true;
      this.recorder.camera.stop();
    }
  }
};
</script>


<style lang="scss" scoped>
.center {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  p{
    margin: 0;
  }
  .count{
    font-size: 6rem !important;
    text-align: center;
    margin-top: 2rem;
  }

  .group-video {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    width: 80%;
    max-width: 20rem;
    height: 25rem;
    margin: 20px auto 30px;
    -webkit-border-radius: 50%/60% 60% 40% 40%;
    -moz-border-radius: 50%/60% 60% 40% 40%;
    border-radius: 50%/40% 40% 60% 60%;
    -webkit-box-shadow: 0px 0px 8px #d5d3d370, 0px 0px 8px #d5d3d34f;
    -moz-box-shadow: 0px 0px 8px #d5d3d370, 0px 0px 8px #d5d3d34f;
    box-shadow: 0px 0px 8px #d5d3d370, 0px 0px 8px #d5d3d34f;
    border: solid .2rem var(--blue);

    .video {
      height: 100%;
    }

  }
  h2{
    text-align: center;
    color: var(--blue-dark);
  }

  .area-into {
    padding: .5rem 0rem;
    border-radius: .5rem;
  }
  .area-time-1{
    position: absolute;
    padding: .5rem 2rem;
    justify-content: center;
    align-items: center;
    border-radius: .5rem;
    text-align: center;
    p{
      font-size: .8rem;
      margin-bottom: 0;
    }
    h4{
      font-size: 4rem;
      margin-top: 0;
    }
  }

  .area-time {
    background-color: var(--white-dark);
    padding: .5rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .5rem;

    img {
      height: 1.5rem;
      margin-right: .5rem;
    }
  }
}

//Iphone11
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .video {
    min-width: 100%;
  }
}

//Iphone11Pro
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .video {
    min-width: 100%;
  }

}

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .video {
    min-width: 100%;
  }
}
</style>