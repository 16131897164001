<div>
    <div class="header">
        <div class="center">

            <img src="../../assets/images/logo-white.svg" alt="Logo Live4Safe" class="logo">
            <div class="text">
                <h3>Bem-vindo(a)</h3>
                <p>Aceite os termos de uso e escolha o tipo de validação para começar</p>
            </div>
            <div class="area-check">
                <CheckBox v-model="terms" :value="terms" :checked="terms"/> <span>Aceito os <a href="#">Termos de uso</a> e <a href="#">Política de Uso de Dados</a></span>
            </div>
        </div>
    </div>
    <div class="center">

        <div class="group-cards" v-if="terms">
            <router-link to="/onboarding">
                <CardHome name="Onboarding" :linkimage="'/assets/images/icon-onboarding.svg'" />
            </router-link>
            <router-link to="/backgroundcheck">
                <CardHome name="Background Check" :linkimage="'/assets/images/icon-backgroundcheck.svg'" />
            </router-link>
            <router-link to="/ocrdocumentos">
                <CardHome name="OCR de documentos " :linkimage="'/assets/images/icon-ocr.svg'" />
            </router-link>
            <router-link to="/reconhecimentofacial">
                <CardHome name="Reconhecimento Facial" :linkimage="'/assets/images/icon-facematch.svg'" />
            </router-link>
            <router-link to="/liveness">
                <CardHome name="Prova de Vida" :linkimage="'/assets/images/icon-proof.svg'" />
            </router-link>
        </div>
    </div>
    <footer>
            <span>©2024 HYTI. All rights reserved.
            </span>
    </footer>
</div>