<div>
    <div class="center">
        <h3>Resultado</h3>
        <div class="message-result">
            <img src="../../assets/images/icon-validado.svg" alt="Icone Resultado">
            <h2>Tudo Certo!</h2>
            <p>Validação efetuada com sucesso!</p>
        </div>
        <div class="group-items">
            <p class="result-item">
                <b>Prova de Vida:</b>
                <span>123.456.789-12</span>
            </p>
            <div class="row2">
                <p class="result-item">
                    <b>Data:</b>
                    <span>15/03/2023</span>
                </p>
                <p class="result-item">
                    <b>Horário:</b>
                    <span>15:45:32</span>
                </p>
            </div>
            <p class="result-item">
                <b>ID Requisição:</b>
                <span>13546846513216846513218</span>
            </p>
        </div>
        <PrimaryButton name="Voltar" pathRedirect="/" />

        <router-link class="lk-redo" to="/liveness">
            Refazer Validação
        </router-link>
    </div>
</div>