<div>
    <div class="center">
        <div class="head">
            <ButtonReturn pathRedirect="/"/>
            <h3>Prova de Vida</h3>
        </div>
        <div class="content">
            <img src="../../../../public/assets/images/ilustration-facematch.svg" alt="Ilustração Prova de Vida" class="illustration">
            <p>
                Vamos iniciar a validação de seus dados.
                Clique no botão abaixo para iniciar a validação.
            </p>
        </div>
        <h5>Importante</h5>
        <p>
            Para a conclusão da validação, é importante estar em local bem iluminado
            e sem acessórios que possam prejudicar a visualização (Boné, óculos, máscara).
        </p>
        <PrimaryButton name="Inicar" pathRedirect="/livenessvalidacao" />
    </div>
</div>