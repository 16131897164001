import SecundaryButton from '../../../components/buttons/SecundaryButton.vue';
import ButtonReturn from '../../../components/buttons/ButtonReturn.vue';
import CameraVideo from '../../../components/CameraVideo.vue';
import InputFileCamera from "@/components/inputs/InputFileCamera.vue"
import OCRService from '@/services/OCRService.js';
import InputText from '@/components/inputs/InputText.vue';
import { mapActions } from 'vuex';
import DocFront from '../../../components/illustration/DocFront.vue';
import DocVerse from '../../../components/illustration/DocVerse.vue';
import DocSelfie from '../../../components/illustration/DocSelfie.vue';

export default {
    name: 'OCRCamera',
    components: {
        SecundaryButton,
        ButtonReturn,
        CameraVideo,
        InputFileCamera,
        InputText,
        DocFront,
        DocSelfie,
        DocVerse
    },

    data() {
        return {
            photo: {
                front: '',
                back: '',
            },

            step: 1,
            stepTexts: {
                title: "Foto da FRENTE do seu documento!",
                documentText: "Tire uma foto da FRENTE do seu documento (RG ou CNH), como mostra o exemplo abaixo.",
            },

        }
    },

    methods: {
        ...mapActions('loading', ['ActionLoadingChange']),

        async stepOne() {
            this.step = 1
            this.stepTexts.title = "Foto da frente do seu documento!";
            this.stepTexts.documentText = "Tire uma foto da frente do seu documento.";
            
        },
        async stepTwo() {
            this.step = 2
            this.stepTexts.title = "Foto do verso do seu documento!";
            this.stepTexts.documentText = "Tire uma foto do verso do seu documento.";

        },
 
        async stepFinal(){
            await this.ActionLoadingChange();
            await this.sendOCR();
            await this.ActionLoadingChange();
            this.redirectResultPage();
        },

        readFile(file, index){
            this.photo[index] = file;
            this.imageSrc = URL.createObjectURL(file);        
        },

        async sendOCR(){
            const formData = new FormData();
            formData.append("image_front",this.photo.front )
            formData.append("image_back", this.photo.back)

            const response = await OCRService.postOCR(formData)
            if(!response.error){
                localStorage.setItem('ocr_id', response.data.RowKey)
                return;
            }
            
            const msg = response.data.response.data?.mensagem ? response.data.response.data.mensagem : 'Algo não saiu como esperado. Tente novamente mais tarde.'
            this.$snackbar.add({ type: 'error',  text: msg})

        },

        nextAvailable(){
            const hashmap = ["front", "back"];

            this.imageSrc = '';

            return this.photo[hashmap[this.step-1]];
        },

        redirectResultPage(){
            this.$router.push("/ocrdocumentos/result")
        },
        

        nextStep(){
            this.step += 1;

            const steps = [this.stepOne, this.stepTwo, this.stepFinal];

            return steps[this.step - 1]();
        }

    }
}