import PrimaryButton from "../../../buttons/PrimaryButton.vue";
import OCRService from "@/services/OCRService.js";

export default {
    name: "CardOcrResult",
    components: {
        PrimaryButton,
    },

    props:{
        typeOcr:{
            type: Number,
            default: 1
        },
        id:{
            required: true,
        }
    },

    data() {
        return {
            ocr: {},
            percent: 0,
            show: {
                ocr: false,
            },

            front: {
                id: "",
                show: {
                    text: false,
                    labels: false,
                    faces: false,
                },

            },

            back: {
                id: "",
                show: {
                    text: false,
                    labels: false,
                    faces: false,
                },

            },
        };
    },

    beforeMount() {
        this.title = "OCR Documentos"

        if(this.typeOcr == 2){
            this.title = "OCR com pessoa segurando documento"
        }        
        this.getData();
    },

    methods: {
        getData() {
           
            const setData = this.setData;     
            const id = this.id? this.id :  localStorage.getItem('ocr_id');     
            console.log(id)
            const interval = setInterval(async function () {
                const response = await OCRService.getOCR(id);

                if (response.data.ocr_status == 'processed') {
                    setData(response.data, 100);
                    clearInterval(interval);
                }

            }, 2000);
        },

        getImagesIds() {
            const {
                image_front,
                image_back
            } = this.ocr;

            this.front.id = image_front.split('/')[4]

            if(image_back != null){
                this.back.id = image_back.split('/')[4]
            }


        },

        analyseStatus(data, status) {
            const result = {
                processed: data,
                queued: "loading",
                pending: "loading",
                "processing ": "loading",
                error: "Algo não ocorreu como esperado",
                execption: "Algo não ocorreu como esperado",
                unknown: "Algo não ocorreu como esperado",
                not_requested: "Não requisitado",
            };

            return result[status];
        },

        setData(ocr, percent) {
            this.percent = percent;
            this.ocr = ocr;
            this.getImagesIds();
        },
    },
};