import api from "./Api"

export default {
    async sendLiveness(postData) {
        const response = await api.post('/liveness/', postData)
            .then((response) => response)
            .catch(err => err);
        return response;
    },

    //https://demo-api.live4safe.com.br/liveness/2bf45c45-62e3-432d-8ade-7c3137d7e791
    async getLiveness(id) {
        const response = await api.get(`/liveness/${id}`)
            .then((response) => response)
            .catch(err => err);
        return response;
    },    
}