import SecundaryButton from '../../../components/buttons/SecundaryButton.vue';
import ButtonReturn from '../../../components/buttons/ButtonReturn.vue';
import Camera from '../../../components/CameraVideo.vue';
import ConfirmButton from '../../../components/buttons/ConfirmButton.vue'
import TheMenu from '../../../components/TheMenu.vue'


export default {
    name: 'OcrDocumentsRgValidation',
    components: {
        SecundaryButton,
        ButtonReturn,
        Camera,
        ConfirmButton,
        TheMenu
    },
    props: {
      closeFn: Function,
    },
}